<template>
    <div>
        <v-autocomplete 
			v-bind="{
				...$attrs,
				...(appendIcon !== undefined && { appendIcon })
			}"
            validate-on-blur 
            v-model="CarrierIDs"
            :items="carriers" 
            cache-items 
			:label="label"
            item-value="ID" 
            item-text="CarrierName"
            :rules="rules || [form_rules.required]"
            :loading="isLoading" 
            :clearable="clearable"
            :return-object="returnObject"
            :multiple="isMultiple()"
        ></v-autocomplete>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    props: {
		'value': {},
		'returnObject': {},
		'multiple': {},
		'rules': {},
		clearable: { type: Boolean, default: true },
		label: { type: String, default: 'Carrier' },
		appendIcon: { type: String, default: undefined },
	},
    data: function() {
        return {
            isLoading: false,
            CarrierIDs: this.value,
            items: [],
            dont_search: false,
            carriers: []
        }
    },
    mounted: function() {
        this.loadCarriers()
    },
    computed: {
        
    },
    methods: {
        isMultiple: function() {
            if(this.multiple !== undefined && this.multiple !== false)
                return true
            if(this.multiple == true)
                return true
            return false
        },
        loadCarriers: function(){
            
            var g = this
            g.isLoading = true
            var options = {
                itemsPerPage:'all', 
                sortBy:'CarrierName', 
                sortDesc:'false', 
                mustSort:'false', 
                multiSort:'false'
            }
            QuilityAPI.getCarriers(options).then(function(json) {
                g.isLoading = false
                g.carriers = json.data
                g.updateCarrier()
            }).catch(function(err) {
                g.isLoading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
        },
        updateCarrier() {
			if (this.returnObject) {
				this.$emit('update', this.CarrierIDs)
			} else {
				const item = this.carriers.find((carrier) => carrier.ID === this.CarrierIDs)
				this.$emit('update', this.CarrierIDs, item)
			}
        },
    },
    watch: {
        'CarrierIDs': function(newV, oldV) {
            this.updateCarrier()
        }
    }
}
</script>
