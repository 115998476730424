<template>
    <div>
        <v-autocomplete
			v-bind="{
				...$attrs,
				...(appendIcon !== undefined && { appendIcon })
			}"
			:disabled="isDemoMode"
			validate-on-blur
			v-model="LeadCode"
			:items="items"
			:search-input.sync="search_leads"
			cache-items
			:item-text="getItemTextLabel"
			item-value="LeadCode"
			:label="label"
			:placeholder="placeholder"
			:rules="rules"
			:loading="isLoading"
			auto-select-first clearable
			:return-object="returnObject"
			:prepend-icon="prependIcon"
			:prepend-inner-icon="prependInnerIcon"
		/>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
export default {
    props: {
		value: {},
		'label': {},
		'placeholder': {},
		'returnObject': {},
		'filters': {},
		'rules': {},
		'division': {},
		'agentCode': {},
		'allLeads': {},
		prependIcon: { type: String, default: 'fas fa-user' },
		appendIcon: { type: String, default: undefined },
		prependInnerIcon: { type: String, default: undefined },
	},
    data: function() {
        return {
            isLoading: false,
            search_leads: null,
            LeadCode: this.value,
            items: [],
            dont_search: false
        }
    },
    mounted: function() {
        if (this.value != null) {
            this.findLead(this.value)
        }
    },
    methods: {
        updateValue: function(v, emit) {
            this.setDontSearch()
            this.LeadCode = v;
            if (emit === true) {
                this.$emit('input', v);
            }
        },
        getItemTextLabel: function(item) {
            return `${item.FirstName} ${item.LastName}, ${item.City ? item.City + ', ' : ''}${item.State}`
        },
        findLead: function(lead) {
            if (typeof lead == 'undefined'){
                return
            }
            if (this.returnObject) {
                var leadcode = lead.LeadCode
            } else {
                var leadcode = lead
            }
            this.isLoading = true
            // Lazily load input items
            var g = this
            QuilityAPI.getLead(leadcode, this.agentCode).then(function(json) {
                g.setDontSearch()
                g.$set(g, 'items', [json])
                g.isLoading = false
            }).catch(function() {
                g.isLoading = false
            });
        },
        //if we just set the agent code for the v-model we don't want to search again for the same agent.
        setDontSearch: function() {
            var g = this
            g.dont_search = true
            setTimeout(function() {
                g.dont_search = false
            }, 1000)
        }
    },
    watch: {
        'LeadCode': function(v) {
            this.updateValue(v, true);
        },
        'value': function(v) {
            this.dont_search = true
            this.findLead(v)
            this.updateValue(v, false);
        },
        'search_leads': debounce(function(val) {
            //if we just set the agent code for the v-model we don't want to search again for the same agent.
            if (this.dont_search) {
                return
            }
            if (val == null || val.length < 3) {
                this.isLoading = false
                return
            }
            // Items have already been loaded
            //if (this.items.length > 0) return

            // Items have already been requested
            //if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            var g = this
            var options = {
                itemsPerPage: 100,
                search: val,
                page: 1,
                division: this.division,
                allLeads: this.allLeads,
                ...this.filters
            }
            QuilityAPI.getLeads(this.agentCode, null, options).then(function(json) {
                g.$set(g, 'items', json.data)
                g.isLoading = false
            });
        }, 250),
    }
}
</script>